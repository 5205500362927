<p-toolbar>
  <ng-template pTemplate="start">
    <p-button label="Download" icon="fa-solid fa-download" [style.marginRight]="'1em'" (onClick)="onDownload()"></p-button>
    <span class="text-primary font-semibold text-xl">
      <p-avatar
        [icon]="socket?.connected ? 'fa-solid fa-signal' : 'fa-solid fa-triangle-exclamation'"
        styleClass="mr-2"
        [pTooltip]="socket?.connected ? 'LogServer: connected' : 'LogServer: disconnected'"
        [style]="{ 'background-color': socket?.connected ? '#009933' : '#e05252' }"
      ></p-avatar>
    </span>
  </ng-template>
  <ng-template pTemplate="center"> </ng-template>
  <ng-template pTemplate="end">
    <span style="margin-right: 1em">
      <p-inputNumber
        [(ngModel)]="fontSize"
        (ngModelChange)="onFontSizeChange()"
        [showButtons]="true"
        suffix=" px"
        spinnerMode="vertical"
        inputId="vertical"
        decrementButtonClass="p-button-secondary"
        incrementButtonClass="p-button-secondary"
        [min]="8"
        [max]="36"
      >
      </p-inputNumber>
    </span>
  </ng-template>
</p-toolbar>

<ngx-monaco-editor [style]="{ 'min-height': '66vh' }" [options]="options" (onInit)="onEditorInit($event)" [(ngModel)]="logs"> </ngx-monaco-editor>
