<div class="logo-container">
  <a href="https://github.com/bee-mar/mmpm" target="_blank">
    <img src="/assets/MagicMirror-Package-Manager.png" alt="MagicMirror Package Manager Logo" style="padding: 30px" />
  </a>
  <div style="float: right">
    <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=L2ML7F8DTMAT2&currency_code=USD&source=ur" target="_blank">
      <img src="/assets/paypal.svg" style="height: 35px; width: 35px; margin-top: 3em; margin-right: 1em" alt="Donate with PayPal" pTooltip="Donate" tooltipPosition="left" />
    </a>
  </div>

  <div style="float: left; margin-left: 1em; margin-top: 1.5em">
    <app-magicmirror-controller></app-magicmirror-controller>
  </div>
</div>

<p-tabView [scrollable]="true" [(activeIndex)]="index" (onChange)="onTabChange($event.index)">
  <!-- <p-tabPanel header="MagicMirror Controller" rightIcon="fa-solid fa-gamepad"> </p-tabPanel> -->

  <p-tabPanel header="Marketplace" rightIcon="fa-solid fa-shop">
    <app-marketplace></app-marketplace>
  </p-tabPanel>

  <p-tabPanel header="Config Editor" rightIcon="fa-solid fa-pen-to-square">
    <app-config-editor></app-config-editor>
  </p-tabPanel>

  <p-tabPanel header="Logs" rightIcon="fa fa-message">
    <app-log-stream-viewer></app-log-stream-viewer>
  </p-tabPanel>
</p-tabView>

<router-outlet></router-outlet>
