<p-button
  label="Shopping Cart"
  icon="fa-solid fa-shopping-cart"
  styleClass="p-button-success ml-2"
  (click)="op.toggle($event)"
  [style]="{ 'margin-left': '1em' }"
  badge="{{ selectedPackages.length }}"
></p-button>

<p-confirmDialog [style]="{ width: '50vw' }"></p-confirmDialog>

<p-overlayPanel #op>
  <div *ngIf="selectedPackages && selectedPackages?.length; else noPackages" class="package-details-grid">
    <p-scroller [items]="selectedPackages" [itemSize]="50" scrollHeight="200px" styleClass="border-1 surface-border" [style]="{ width: '300px', height: '200px' }">
      <ng-template pTemplate="item" let-package>
        <div class="flex align-items-center p-2" style="height: 50px">
          {{ package.title }}
          <span>
            <i *ngIf="package.is_installed; else notInstalled" class="fa-solid fa-trash" [style.color]="'#e05252'"></i>
            <ng-template #notInstalled>
              <i class="fa-solid fa-download" [style.color]="'#009933'"></i>
            </ng-template>
          </span>
        </div>
      </ng-template>
    </p-scroller>

    <p-divider></p-divider>

    <div class="confirm-button">
      <p-button label="Checkout" [disabled]="!(selectedPackages && selectedPackages.length > 0)" (click)="onCheckout()" icon="fa-solid fa-check"></p-button>
    </div>

    <p-toolbar>
      <ng-template pTemplate="start"> <i class="fa-solid fa-download" [style.color]="'#009933'"></i> = Install </ng-template>
      <ng-template pTemplate="center">
        <span class="text-primary font-semibold text-xl"></span>
      </ng-template>
      <ng-template pTemplate="end"> <i class="fa-solid fa-trash" [style.color]="'#e05252'"></i> = Remove </ng-template>
    </p-toolbar>
  </div>

  <ng-template #noPackages>
    <div>No packages selected.</div>
  </ng-template>
</p-overlayPanel>

<p-toast></p-toast>
