<div class="card" [style]="{ 'min-height': '66vh' }">
  <p-toolbar>
    <ng-template pTemplate="start">
      <app-custom-package-manager [(loading)]="loading"></app-custom-package-manager>
    </ng-template>
    <ng-template pTemplate="end">
      <app-database-info [(loading)]="loading"></app-database-info>
      <app-shopping-cart [(loading)]="loading" [(selectedPackages)]="selectedPackages"></app-shopping-cart>
    </ng-template>
  </p-toolbar>

  <p-table
    #dt
    [value]="packages"
    styleClass="p-datatable-striped p-datatable-sm"
    [rows]="10"
    [showCurrentPageReport]="true"
    selectionMode="multiple"
    [(selection)]="selectedPackages"
    [rowsPerPageOptions]="[5, 10, 25, 50]"
    [style]="{ 'min-height': '66vh' }"
    [loading]="loading"
    [paginator]="true"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    [tableStyle]="{ 'min-width': '75rem' }"
    [filterDelay]="0"
  >
    <ng-template pTemplate="header">
      <tr>
        <th style="width: 4rem">
          <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
        </th>
        <th style="min-width: 10rem" pSortableColumn="category">
          <div class="flex align-items-center">
            Category
            <p-columnFilter field="category" matchMode="in" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-filter="filterCallback">
                <p-multiSelect [options]="categories" [(ngModel)]="selectedCategories" placeholder="Select Categories" (onChange)="filter($event.value)">
                  <ng-template let-category pTemplate="item">
                    <span class="category-icon-text">
                      {{ category }}
                      <i [class]="icons[category].logo || 'fa fa-question-circle'" [style.color]="icons[category].color || 'black'"></i>
                    </span>
                  </ng-template>
                </p-multiSelect>
              </ng-template>
            </p-columnFilter>
            <p-sortIcon field="category"></p-sortIcon>
          </div>
        </th>
        <th style="min-width: 10rem" pSortableColumn="title">
          <div class="flex align-items-center">
            Title
            <p-columnFilter type="text" field="title" display="menu" [showOperator]="false" [showAddButton]="false" filterDelay="0"> </p-columnFilter>
            <p-sortIcon field="title"></p-sortIcon>
          </div>
        </th>
        <th style="min-width: 10rem" pSortableColumn="author">
          <div class="flex align-items-center">
            Author
            <p-columnFilter type="text" field="author" display="menu" [showOperator]="false" [showAddButton]="false"> </p-columnFilter>
            <p-sortIcon field="author"></p-sortIcon>
          </div>
        </th>
        <th style="min-width: 10rem" pSortableColumn="is_installed">
          <div class="flex align-items-center">
            Installed
            <p-columnFilter field="is_installed" matchMode="equals" display="menu" [showMatchModes]="false" [showOperator]="false" [showAddButton]="false">
              <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                <p-dropdown [options]="installedOptions" [(ngModel)]="selectedInstalled" placeholder="Is Installed?" (onChange)="filter($event.value, 'is_installed')">
                </p-dropdown>
              </ng-template>
            </p-columnFilter>
            <p-sortIcon field="is_installed"></p-sortIcon>
          </div>
        </th>
        <th style="min-width: 10rem">Details</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-package>
      <tr class="p-selectable-row">
        <td>
          <p-tableCheckbox [value]="package"></p-tableCheckbox>
        </td>
        <td>
          <span class="category-icon-text">
            {{ package?.category }}
            <i [class]="icons[package.category].logo || 'fa fa-question-circle'" [style.color]="icons[package.category].color || 'black'"></i>
          </span>
        </td>
        <td>
          {{ package?.title }}
        </td>
        <td>
          {{ package?.author }}
        </td>
        <td>
          <i class="pi pi-{{ package?.is_installed ? 'check' : 'times' }}-circle" [style.color]="package?.is_installed ? '#009933' : '#e05252'"></i>
        </td>
        <td>
          <p-button [raised]="true" severity="info" icon="fa-solid fa-circle-info" (click)="selectedPackage = package; displayDetailsDialog = true"> </p-button>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="5">Unable to load packages.</td>
      </tr>
    </ng-template>
  </p-table>

  <app-package-details-viewer [(selectedPackage)]="selectedPackage" [(display)]="displayDetailsDialog"> </app-package-details-viewer>
</div>
