<div class="card">
  <p-toolbar>
    <ng-template pTemplate="start">
      <p-button
        label="Save"
        icon="fa-solid fa-floppy-disk"
        [style]="{ 'margin-right': '1em' }"
        (click)="onSaveFile()"
        [disabled]="state[file].current === state[file].saved"
        severity="success"
      >
      </p-button>

      <p-toast></p-toast>
      <p-menu #menu [model]="fileOptions" [popup]="true"></p-menu>
      <p-button (click)="menu.toggle($event)" icon="fa-solid fa-file" label="Files" severity="primary"></p-button>
    </ng-template>
    <ng-template pTemplate="center">
      <span class="text-primary font-semibold text-xl">
        <i class="fa-solid fa-code" [style]="{ color: 'white', 'margin-right': '1em' }"></i>
        <code>{{ file }}</code>
      </span>
    </ng-template>
    <ng-template pTemplate="end">
      <span style="margin-right: 1em">
        <p-inputNumber
          [(ngModel)]="fontSize"
          (ngModelChange)="onFontSizeChange()"
          [showButtons]="true"
          suffix=" px"
          spinnerMode="vertical"
          inputId="vertical"
          decrementButtonClass="p-button-secondary"
          incrementButtonClass="p-button-secondary"
          [min]="8"
          [max]="36"
        >
        </p-inputNumber>
      </span>
    </ng-template>
  </p-toolbar>
  <ngx-monaco-editor (keydown)="handleKeyDown($event)" [style]="{ 'min-height': '66vh' }" [options]="options" (onInit)="onEditorInit($event)" [(ngModel)]="state[file].current">
  </ngx-monaco-editor>
</div>
